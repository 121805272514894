const routePaths = {
  home: '/',
  dashboard: '/dashboard',
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/',
  logout: '/logout',
  changePassword: '/change-password',
  registerInvitee: '/register-invitee/',
  sites: '/sites',
  impersonate: '/impersonate',
  userManagement: '/users',
  distributionLists: '/distribution-lists/',
  customFilters: '/custom-filters/',
  data: '/data',
  notFound: '/404',
  implementation: '/implementation',
  editSite: '/edit-site',
  exports: '/exports',
  importManager: '/import-manager',
  exportManager: '/export-manager',
  lookerSiteManager: '/looker-site-manager',
  dataTableManager: '/table-manager', // when this starts with 'data' the sidebar highlighting gets messed up with the actual 'data' item
  timeBombManager: '/timebombs',
  marketplaceManager: '/marketplace',
  buyerPortalAccess: '/buyerx-access',
  inventoryManager: '/inventory-manager',
  featureFlagManager: '/feature-flag-manager',
  negotiationManager: '/negotiation-manager',
};

export default routePaths;
